import { datadogRum } from '@datadog/browser-rum';

import { env } from '@/env.mjs';

function initDatadogRum() {
  datadogRum.init({
    applicationId: env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
    clientToken: env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    site: `datadoghq.com`,
    service: `artist-web`,
    env: env.NEXT_PUBLIC_DATADOG_RUM_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: env.NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate:
      env.NEXT_PUBLIC_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: `mask-user-input`,
  });
  datadogRum.startSessionReplayRecording();
}

export default initDatadogRum;
