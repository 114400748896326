'use client';

import { usePrevious } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import useSWR from 'swr';

import getBuildId from '@/utils/getBuildId';

export default function BuildIdChecker() {
  const { data: buildId } = useSWR(`/api/build-id`, () => getBuildId());

  const previousBuildId = usePrevious(buildId);

  useEffect(() => {
    if (buildId && previousBuildId && buildId !== previousBuildId) {
      // eslint-disable-next-line no-alert
      if (window.confirm(`New version available. Please refresh the page.`)) {
        window.location.reload();
      }
    }
  }, [buildId, previousBuildId]);

  return null;
}
