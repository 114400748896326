import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Button, ButtonProps } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  LoadingContextState,
  useLoading,
} from '@/utils/Loading/LoadingProvider';

type Options = {
  title: string;
  message?: string;
  buttons: ((
    onClose: () => void,
    loader: LoadingContextState['loader'],
  ) => ButtonProps)[];
};

const ConfirmContext = createContext<{
  confirm: (options: Options) => void;
} | null>(null);

function Confirm(props: {
  options: Options;
  onClose: {
    (): void;
  };
}) {
  const { options, onClose } = props;
  const { message, title, buttons } = options;
  const { loader } = useLoading();

  return (
    <Dialog
      open
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-318">
        <ScrollArea className="max-h-[90vh]">
          <div className="flex size-full flex-col p-16">
            <div className="px-8 pt-8">
              <div className="px-8 pt-8">
                <span className="header-4-m text-gray-0">{title}</span>
                <div className="h-6" />
                <span className="body-2-r whitespace-pre-line text-gray-100">
                  {message}
                </span>
              </div>
            </div>

            <div className="h-32" />
            <div className="flex items-center gap-8 md:justify-end">
              {buttons.map((button, index) => {
                const buttonProps = button(onClose, loader);

                return (
                  <Button
                    className="w-full md:w-139"
                    size="lg"
                    {...buttonProps}
                    /* FIXME */
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

export function ConfirmProvider({ children }: { children?: ReactNode }) {
  const [options, setOptions] = useState<Options | null>(null);

  const value = useMemo(
    () => ({
      confirm: setOptions,
    }),
    [],
  );

  return (
    <ConfirmContext.Provider value={value}>
      {options && (
        <Confirm onClose={() => setOptions(null)} options={options} />
      )}
      {children}
    </ConfirmContext.Provider>
  );
}

export default function useConfirm() {
  const context = useContext(ConfirmContext);

  if (!context) {
    throw new Error(`useConfirm must be used within an ConfirmProvider`);
  }

  return context;
}
