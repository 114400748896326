'use client';

import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/nextjs';
import React, { ReactNode } from 'react';
import { IntercomProvider } from 'react-use-intercom';

import CustomToaster from '@/components/Molecule/CustomToaster';
import InternalServerError from '@/components/Pages/Error/InternalServerError';
import ClientInitializer from '@/components/ReactClientComponents/ClientInitializer';
import { env } from '@/env.mjs';
import client from '@/graphql/apollo';
import { ConfirmProvider } from '@/hooks/useConfirm';
import LoadingProvider from '@/utils/Loading/LoadingProvider';

export default function ClientProviders({
  children,
  userAgent,
}: {
  children?: ReactNode;
  userAgent: string;
}) {
  return (
    <ErrorBoundary fallback={<InternalServerError />}>
      <CustomToaster />
      <ApolloProvider client={client}>
        <LoadingProvider>
          <ConfirmProvider>
            <IntercomProvider
              appId={env.NEXT_PUBLIC_INTERCOM_APP_ID ?? ``}
              autoBoot={!!env.NEXT_PUBLIC_INTERCOM_APP_ID}
            >
              {children}
            </IntercomProvider>
          </ConfirmProvider>
        </LoadingProvider>
      </ApolloProvider>
      <ClientInitializer />
    </ErrorBoundary>
  );
}
