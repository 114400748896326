'use client';

import { Analytics } from '@vercel/analytics/react';
import React, { useEffect } from 'react';

import initDatadogRum from '@/utils/initDatadogRum';
import { useRouteChangeComplete } from '@/utils/mixpanel';

export default function ClientInitializer() {
  useEffect(() => {
    const script = document.createElement(`script`);

    script.src = `https://paperform.co/__embed.min.js`;
    document.body.appendChild(script);
    initDatadogRum();
  }, []);
  useRouteChangeComplete();

  return <Analytics />;
}
